import {
  Injectable
} from '@angular/core';
import {
  HttpClient,
  HttpHeaders
} from '@angular/common/http';
import {
  AuthService
} from './auth.service';
import {
  environment
} from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OfferService {

  public offers: Array < Offer > = [];
  public highlightedOffers: Array < Offer > = [];
  public pagination = {
    length: 0,
    pageIndex: 0,
    pageSize: 15,
    previousPageIndex: 0
  };
  public highlightedPagination = {
    length: 0,
    pageIndex: 0,
    pageSize: 15,
    previousPageIndex: 0
  };

  constructor(private http: HttpClient, public auth: AuthService) {}

  updateOfferSetting(offer: any): void {

    this.http.patch(environment.apiUrl + 'users/' +
        this.auth.user.id + '/offer_user_settings/' + offer.id, offer.user_settings, {
          headers: this.getHeaders()
        })
      .subscribe(Response => {
        this.getOffers();
        if (this.auth.loggedIn) {
          this.getHighlightedOffers();
        }
      });
  }

  public getOffers(keywords = ''): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const url = environment.apiUrl + 'offers' + '?page=' + (this.pagination.pageIndex + 1) + '&keywords=' + keywords;
      this.http.get(url, {
          headers: this.getHeaders()
        })
        .subscribe(Response => {
          const resp: any = Response;
          this.offers = resp.data;
          this.pagination.length = resp.total;
          resolve(true);
        });
    });
  }

  public getHighlightedOffers(): void {
    if (this.auth.loggedIn) {
      const url = environment.apiUrl + 'offers' + '?page=' + (this.highlightedPagination.pageIndex + 1) + '&user_settings[highlighted]=1';
      this.http.get(url, {
          headers: this.getHeaders()
        })
        .subscribe(Response => {
          const resp: any = Response;
          this.highlightedOffers = resp.data;
          this.highlightedPagination.length = resp.total;
          if (this.highlightedPagination.length <= this.highlightedPagination.pageSize && this.highlightedPagination.pageIndex > 0) {
            this.highlightedPagination.pageIndex = 0;
            this.getHighlightedOffers();
          }
        });
    }
  }

  trackBy(index, item): number {
    return item.id;
  }

  getHeaders(): any {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.auth.token}`
    });
  }
}

export interface Offer {
  created_at: string;
  icon: string;
  id: number;
  tags: Array < any > ;
  text: string | null;
  title: string | null;
  updated_at: string | null;
  url: string;
  user_settings: any;
}
