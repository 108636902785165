import { AuthService } from './../auth.service';
import { Component, OnInit } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import { OfferService } from './../offer.service';

@Component({
  selector: 'app-login-dialog',
  template: `
    <h1 mat-dialog-title>Login</h1>
    <div mat-dialog-content>
      <mat-form-field>
        <mat-label>E-Mail</mat-label>
        <input matInput [(ngModel)]="email">
      </mat-form-field><br>
      <mat-form-field>
        <mat-label>Passwort</mat-label>
        <input type="password" matInput [(ngModel)]="password">
      </mat-form-field>
    </div>
    <div mat-dialog-actions>
      <button mat-button mat-dialog-close>Abbrechen</button>
      <button mat-button cdkFocusInitial (click)="login()">Ok</button>
    </div>
  `,
  styles: [
  ],
})
export class LoginDialogComponent implements OnInit {

  email = 'mail@aaron-enders.de';
  password = 'secret123';

  constructor(private auth: AuthService, private matDialog: MatDialog, public offers: OfferService) { }

  ngOnInit(): void {
    //
  }

  login(): void {
      this.auth.login(this.email, this.password).then((done) => {
        this.offers.getOffers();
        this.offers.getHighlightedOffers();
        this.matDialog.closeAll();
      });
  }

}
