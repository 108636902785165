import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { environment } from '../environments/environment';
import {User} from './shared/models/user.model';

@Injectable({
  providedIn: 'root'
})

export class AuthService {

  constructor(private http: HttpClient) {
    this.getUser();
  }

  loggedIn = false;
  token = null;
  user: any = {};

  getUser() {

    this.token = localStorage.getItem('token');

    if (this.token) {
      this.loggedIn = true;

      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json',
          Authorization: `Bearer ${this.token}`
        })
      };

      this.http.get(environment.apiUrl + 'auth/user', httpOptions)
      .subscribe(Response => {
        const resp: any = Response;
        this.user = new User(resp.user, this);
      },
      err => {
        if (err.status === 401) {
          this.logout();
        }
      });
    }
  }

  logout(): void {
    localStorage.removeItem('token');
    this.user = null;
    this.loggedIn = false;
    this.token = null;
  }

  login(email, password): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (!email || !password) {
        reject(false);
      }
      this.http.post(environment.apiUrl + 'auth/login', {email, password})
      .subscribe(Response => {
          const resp: any = Response;
          if (resp.token) {
            localStorage.setItem('token', resp.token);
            this.token = resp.token;
            this.loggedIn = true;
            resolve(true);
          }
        });
    });
  }

   updateUser(changes): Promise<boolean> {
    return new Promise((resolve, reject) => {

      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json',
          Authorization: `Bearer ${this.token}`
        })
      };

      this.http.put(environment.apiUrl + 'auth/user', changes, httpOptions)
      .subscribe(Response => {
        resolve(true);
      });
    });
  }
}
