
<div class="mat-app-background basic-container">
  <mat-toolbar>
    Jobs
    <span style="flex: 1 1 auto;"></span>
    <button mat-icon-button aria-label="Filter anzeigen"
    matTooltip="Gelesene Anzeigen ausblenden"
    *ngIf="auth.loggedIn" (click)="toggleHideSeenOffers()">
      <mat-icon>{{auth.user.hideSeenOffers ? 'visibility_off' : 'visibility'}}</mat-icon>
    </button>
    <button mat-icon-button aria-label="Filter anzeigen" matTooltip="Filter anzeigen" [color]="showFilters ? 'primary' : 'grey'" *ngIf="auth.loggedIn" (click)="showFilters = !showFilters">
      <mat-icon>filter_list</mat-icon>
    </button>
    <button mat-button (click)="openLogin()" *ngIf="!auth.loggedIn">Login</button>
    <button mat-button (click)="auth.logout(); offers.getOffers();" *ngIf="auth.loggedIn">Logout</button>
  </mat-toolbar>
  <mat-drawer-container>
    <mat-drawer class="sidebar" mode="side" [opened]="auth.loggedIn">
      <app-highlighted-offers></app-highlighted-offers>
    </mat-drawer>
    <mat-drawer-content>
      <div class="spinner" *ngIf="loading">
        <mat-spinner></mat-spinner>
      </div>
      <div *ngIf="!loading">
        <div class="keywords" *ngIf="showFilters && auth.loggedIn && auth.user">
          <mat-form-field class="example-chip-list">
            <mat-label>Filter</mat-label>
            <mat-chip-list [multiple]="true" #chipList aria-label="Filter">
              <mat-chip *ngFor="let keyword of auth.user.keywordsArray" [selectable]="true" [selected]="isKeywordSelected(keyword)"
                        (click)="toggleKeywordSelection(keyword)" [color]="'primary'"
                        [ngStyle]="{ border: (keyword.startsWith('!') ?'1px solid #ff9494' : null) }"
                       [removable]="true" (removed)="auth.user.removeKeyword(keyword);offers.getOffers();">
                {{keyword}}
                <mat-icon matChipRemove>cancel</mat-icon>
              </mat-chip>
              <input placeholder="Hinzufügen..."
                     [matChipInputFor]="chipList"
                     [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                     [matChipInputAddOnBlur]="true"
                     (matChipInputTokenEnd)="auth.user.addKeyword($event.input.value);$event.input.value = '';offers.getOffers();">
            </mat-chip-list>
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>Seiten ausschließen</mat-label>
            <mat-select [(ngModel)]="auth.user.siteGroupExclusionArray" multiple (selectionChange)="updateExcludeSiteGroups($event)">
              <mat-option *ngFor="let siteGroup of siteGroups.siteGroups" [value]="siteGroup.id">{{siteGroup.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <mat-paginator [length]="offers.pagination.length"
          [pageIndex]="offers.pagination.pageIndex"
          [pageSize]="offers.pagination.pageSize"
          (page)="changePage($event)"
        >
        </mat-paginator>
        <div class="wrapper">
          <mat-card *ngFor="let e of offers.offers; trackBy: offers.trackBy" [ngClass]="{
            'offer--seen': e.user_settings && e.user_settings.seen,
            'offer--highlighted': e.user_settings && e.user_settings.highlighted,
            'offer--applied': e.user_settings && e.user_settings.applied
          }"  >
            <mat-card-title>
              <img *ngIf="e.icon" [src]="e.icon" alt="icon" class="offer-icon" />
              <a rel="noopener noreferrer" href="{{e.url}}" (click)="offerClick(e, $event)">{{e.title}}</a>
            </mat-card-title>
            <mat-card-content *ngIf="!e.user_settings || !e.user_settings.seen">
              {{e.text}}
              <mat-chip-list aria-label="Tags" *ngIf="e.tags">
                <mat-chip *ngFor="let tag of e.tags">{{tag.label}}</mat-chip>
              </mat-chip-list>
            </mat-card-content>
            <mat-card-actions style="display: flex; ">
              <button [disabled]="!auth.loggedIn || !this.auth.user.id" mat-button (click)="(!e.user_settings ? e.user_settings = {seen: true} : e.user_settings.seen = !e.user_settings.seen ); offers.updateOfferSetting(e)">Als gesehen markieren</button>
              <button [disabled]="!auth.loggedIn || !this.auth.user.id" mat-button (click)="(!e.user_settings ? e.user_settings = {highlighted: true} : e.user_settings.highlighted = !e.user_settings.highlighted ); offers.updateOfferSetting(e)">Merken</button>
              <button [disabled]="!auth.loggedIn || !this.auth.user.id" mat-button (click)="(!e.user_settings ? e.user_settings = {applied: true} : e.user_settings.applied = !e.user_settings.applied ); offers.updateOfferSetting(e)">Beworben</button>
              <span style="flex: 1 1 auto;"></span>
              <span style="margin: 8px;">{{e.created_at | date:'dd.MM.yyy HH:mm'}} Uhr</span>
            </mat-card-actions>
          </mat-card>

        </div>
        <mat-paginator [length]="offers.pagination.length"
          [pageSize]="offers.pagination.pageSize"
          [pageIndex]="offers.pagination.pageIndex"
          (page)="changePage($event)"
        >
        </mat-paginator>
      </div>
    </mat-drawer-content>
  </mat-drawer-container>
</div>
