import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { LoginDialogComponent } from './login-dialog/login-dialog.component';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from './auth.service';
import { OfferService } from './offer.service';
import { SiteGroupService } from './site-group.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass'],
})

export class AppComponent implements OnInit {
  separatorKeysCodes: number[] = [ENTER, COMMA];
  offersList: object = {};
  keywordSelectionArray: string[] = [];
  loading = false;
  showFilters = false;

  constructor(
    public dialog: MatDialog, public auth: AuthService, public offers: OfferService,
    public siteGroups: SiteGroupService, private http: HttpClient
  ){

  }

  toggleKeywordSelection(keyword): void {
    if (this.keywordSelectionArray.includes(keyword)) {
      this.keywordSelectionArray = this.keywordSelectionArray.filter(k => k !== keyword);
    } else {
      this.keywordSelectionArray.push(keyword);
    }
    this.offers.getOffers(this.keywordSelectionArray.join(','));
  }

  isKeywordSelected(keyword): boolean {
    return this.keywordSelectionArray.includes(keyword);
  }

  openLogin(): void {
    const dialogRef = this.dialog.open(LoginDialogComponent);
  }

  changePage(page): void {
    this.offers.pagination = page;
    this.offers.getOffers().then(() => {
      window.scrollTo(0, 0);
    });
  }

  offerClick(offer, event): void {
    if (!offer.url) {
      alert('Weiterleitung zur Ausschreibung nur nach Registrierung möglich.');
      event.preventDefault(); event.stopPropagation();
    }
  }

  updateExcludeSiteGroups($event): void {
    this.auth.user.updateExcludeSiteGroups($event.value.join(',')).then(() => {
      this.offers.getOffers();
    });
  }

  toggleHideSeenOffers(): void {
    this.auth.user.toggleHideSeenOffers().then(() => {
      this.offers.getOffers();
    });
  }

  ngOnInit(): void {
    this.loading = true;
    this.offers.getOffers().then(() => {
      this.loading = false;
    });
    if (this.auth.loggedIn) {
      this.siteGroups.getSiteGroups();
    }
  }

}
