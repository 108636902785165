import {
  AuthService
} from '../../auth.service';

export class User {
  id: number;
  email: string;
  keywords: string;
  keywordsArray: string[];
  excludeSiteGroupIds: string;
  siteGroupExclusionArray: number[];
  hideSeenOffers: boolean;

  constructor(user, public auth: AuthService) {
    {
      this.id = user.id;
      this.email = user.email;
      this.hideSeenOffers = user.hide_seen_offers;
      this.keywords = user.keywords;
      this.keywordsArray = this.keywords ? this.keywords.split(',') : [];
      this.excludeSiteGroupIds = user.exclude_site_group_ids;
      this.siteGroupExclusionArray = user.exclude_site_group_ids ? user.exclude_site_group_ids.split(',').map(e => parseInt(e, 10)) : [];
    }
  }

  addKeyword(keyword): Promise < boolean > {
    return new Promise((resolve, reject) => {
      if (keyword) {
        this.keywordsArray.push(keyword);
      }
      this.auth.updateUser({
        keywords: this.keywordsArray.join(',')
      }).then(success => {
        resolve(success);
      });
    });
  }

  removeKeyword(keyword): Promise < boolean > {
    return new Promise((resolve, reject) => {
      this.keywordsArray = this.keywordsArray.filter(k => k !== keyword);
      this.auth.updateUser({
        keywords: this.keywordsArray.join(',')
      }).then(success => {
        resolve(success);
      });
    });
  }

  updateExcludeSiteGroups(excludeSiteGroupIds): Promise < boolean > {
    return new Promise((resolve, reject) => {
      this.auth.updateUser({
        exclude_site_group_ids: excludeSiteGroupIds
      }).then(success => {
        resolve(success);
      });
    });
  }

  toggleHideSeenOffers(): Promise < boolean > {
    this.hideSeenOffers = !this.hideSeenOffers;
    return new Promise((resolve, reject) => {
      this.auth.updateUser({
        hide_seen_offers: this.hideSeenOffers
      }).then(success => {
        resolve(success);
      });
    });
  }
}
