import { Injectable } from '@angular/core';
import {
  environment
} from '../environments/environment';
import {
  HttpClient,
  HttpHeaders
} from '@angular/common/http';
import {
  AuthService
} from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class SiteGroupService {

  constructor(private http: HttpClient, public auth: AuthService) {}

  public siteGroups: Array < SiteGroup > = [];

  public getSiteGroups(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const url = environment.apiUrl + 'site_groups';
      this.http.get(url, {
          headers: this.getHeaders()
        })
        .subscribe(Response => {
          const resp: any = Response;
          this.siteGroups = resp.data;
          resolve(true);
        });
    });
  }

  getHeaders(): any {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.auth.token}`
    });
  }
}

export interface SiteGroup {
  created_at: string;
  name: string;
}
